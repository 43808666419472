import React from 'react';
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"


import { Row, Col } from 'antd';

const Standard = ({data}) => {
    const intl = useIntl()

    const { slug, keywords } = data.markdownRemark.frontmatter
    const __html = data.markdownRemark.html

    const breadcrumb = slug.split('/')

    return (
        <Layout page={breadcrumb[0]} titles={breadcrumb}>
            <SEO title={intl.formatMessage({ id: 'STANDARD.TITLE' })} keywords={keywords}/>
            <div className="container">
                <Row type="flex" justify="center" align="top" gutter={24}>
                    <Col xs={24} sm={24} md={14} lg={24} xl={24}>
                        <div style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{ __html }} />        
                    </Col>
                </Row>
            </div>
        </Layout>
    );
};

export default Standard;

export const query = graphql`
    query($slug: String!){
        markdownRemark(frontmatter: { slug: { eq: $slug } }){
            html
            frontmatter {
                slug
                title
                keywords
                photos {
                    src
                    title
                }
            }
        }
    }
`